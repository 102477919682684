<template>
  <div>
    <div class="column is-3">
      <b-field
          label="Apellido Paterno"
          label-position="on-border"
      >
        <b-input
            ref="patient_last_name"
            @keypress.enter.native="$refs.patient_other_name.focus()"
            v-model="params.last_name"
            :placeholder="$t('patient.attributes.last_name')"
            autofocus
        ></b-input>
      </b-field>
    </div>
    <div class="column is-3">
      <b-field
          label="Apellido Materno"
          label-position="on-border"
      >
        <b-input
            ref="patient_other_name"
            v-model="params.other_name"
            @keypress.enter.native="$refs.patient_name.focus()"
            :placeholder="$t('patient.attributes.other_name')"
        ></b-input>
      </b-field>
    </div>
    <div class="column is-5">
      <b-field
          label="Nombre(s)"
          label-position="on-border"
      >
        <b-input
            v-model="params.name"
            ref="patient_name"
            :placeholder="$t('patient.attributes.name')"
            @keypress.enter.native="onSearch"
        ></b-input>
      </b-field>
    </div>
    <div class="column is-1">
      <p class="control">
        <button class="button has-icon is-primary" type="button" @click="onSearch">
          <b-icon icon="search"></b-icon>
        </button>
      </p>
    </div>
    <b-modal :active.sync="isComponentModalActive"
             has-modal-card full-screen :can-cancel="false">
      <modal-select-patient :data="data"
                            :can-search-orders="type === 'order'"
                            paginated
                            :type="type"
                            :params="params"
                            :has-company="hasCompany"
                            @selectOrder="val => $emit('selectOrder', val)"
                            @select="onSelectPatient">
      </modal-select-patient>
    </b-modal>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import ModalSelectPatient from "./ModalSelectPatient";
export default {
  name: "SelectPatient",
  components: {ModalSelectPatient},
  props: {
    primaryKey: {
      type: String,
      default: 'id'
    },
    patient: Object|String|Number,
    hasCompany: Boolean,
    onlySearch: Boolean,
    type : {
      type: String,
      default: 'order'
    }
  },
  computed: {
    ...mapState({
      isComponentModalActive: function(state){
        if(this.type !== 'salescallcenter'){
          return state.pos.showModalSelectPatient
        }else{
          return state.salescallcenter.showModalSelectPatient
        }
      }
    }),
  },
  data() {
    return {
      apiUrl: 'patients',
      params: {
        last_name: '',
        other_name: '',
        name: '',
        perPage: 200,
        page: 1
      },
      data: [],
    }
  },
  watch: {
    patient: {
      deep: true,
      handler(val) {
        if (!! val) {
          this.prepareData()
          this.params = {
            ...this.params,
            ...val
          }
        }
      }
    }
  },
  mounted() {
    this.$store.subscribeAction((action, state) => {
      if (action.type === 'order/removePatient') {
        this.$refs.patient_name.focus();
      }
      if(action.type === 'order/clearOrder') {
        this.data = [];
      }
    });
  },
  methods: {
    prepareData() {
      this.params = {
        last_name: '',
        other_name: '',
        name: '',
        perPage: 200,
        page: 1
      }
    },
    setModalState(val){
      if(this.type !== 'salescallcenter'){
        this.$store.dispatch('setShowModalSelectPatient', val);
      }else{
        this.$store.dispatch('salescallcenter/setShowModalSelectPatient', val);
      }
    },
    async onSearch() {
      const {last_name, other_name, name} = this.params;
     if (last_name !== '' && other_name !== '' && name !== '') {
       this.$loader.show();
       await this.getAll(this.params).then(({data = []}) => {
         this.data = data || [];
         if (this.data.length === 1) {
           this.onSelectPatient(data[0]);
         } else {
           this.setModalState(true);
           //this.$store.dispatch('setShowModalSelectPatient', true);
         }
       });
       this.$loader.hide();
     }
    },
    onSelectPatient(data) {
      if(!data.cell_phone || !data.email ){
        this.$buefy.dialog.alert({
          title: 'Información requerida',
          message: 'No olvides actualizar el número de teléfono móvil para el WhatsApp en el campo teléfono móvil y el correo electrónico en el campo correo.',
          size: 'super-large'
        })
      }else if(data.cell_phone.toString().length !== 10){
        this.$buefy.dialog.alert({
          title: 'Número de whatsapp incorrecto',
          message: 'El número de whatsapp registrado no tiene 10 digítos'
        })
      }
      this.$emit('update:patient', data);
      this.$emit('select', data);
      if (! this.onlySearch) {
        switch (this.type){
          case 'medicalspecialties':
            this.$store.dispatch('medicalspecialties/setPatient', data);
            //this.$store.dispatch('setShowModalSelectPatient', false);
            this.setModalState(false);
            break;
          case 'quotation':
            this.$store.dispatch('quotation/setPatient', data);
            break;
          case 'order':
          default:
            this.$store.dispatch('order/setPatient', data);
            break;
        }
        //this.$store.dispatch('setShowModalSelectPatient', false);
        this.setModalState(false);
      }else{
        //this.$store.dispatch('setShowModalSelectPatient', true);
        this.setModalState(true);
      }
    }
  }
}
</script>

<style scoped>

</style>
